<template>
  <div class="modal-body">
    <b-row>
      <b-col md="6">
        <label for="">Delivery Date</label> <br />
        <div class="rounded border px-2 padding-y">
          {{ form.delivery_date_format }}
        </div>
      </b-col>
      <b-col md="6">
        <label for="">Insurance</label> <br />
        <div class="rounded border px-2 padding-y">RM {{ form.insurance }}</div>
      </b-col>
      <b-col md="6" class="mt-1">
        <label for="">Tax</label> <br />
        <div class="rounded border px-2 padding-y">RM {{ form.tax }}</div>
      </b-col>
      <b-col md="6" class="mt-1">
        <label for="">Quotation</label> <br />
        <div class="rounded border px-2 padding-y">RM {{ form.quotation }}</div>
      </b-col>
      <b-col md="12" class="mt-1">
        <label for="">Total</label> <br />
        <div class="rounded border px-2 padding-y total">RM {{ form.total }}</div>
      </b-col>

      <b-col md="12" class="mt-2">
        <label for="">Quote Document</label> <br />
        <div class="rounded border px-2 padding-y">
          <a v-if="form.document" :href="form.document" target="_blank"> View quote document </a>
          <span v-else>No Document</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<!-- eslint-disable arrow-parens -->
<!-- eslint-disable comma-dangle -->
<script>
import axios from 'axios'

export default {
  name: 'QuotationForm',

  props: {
    order_id: {
      type: Number,
      required: true,
    },
    quotation: {
      required: true,
    },
  },
  data() {
    return {
      isChangeDocument: false,
      file: null,
      isLoading: false,
      form: this.quotation,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.padding-y {
  padding-top: 7px;
  padding-bottom: 7px;
}
.total {
  font-weight: 800;
  color: black;
  background-color: rgba(216, 185, 127, 0.668);
}
</style>
